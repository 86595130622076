<template>
  <Header />
  <div @scroll="getNextPage(select)" class="full">
    <SearchBar
      @searched="searched"
      style="padding-top: 20px;padding-bottom: 20px"
      class="wrap"
      place-holder="Search for Question"
    ></SearchBar>
    <div class="container-app container" v-if="!loading">
      <div class="add questions" v-if="select">
        <div class="header">
          <h1>Add Questions</h1>
        </div>
        <label>Check to add question manually</label>
        <input type="checkbox" name="manual" v-model="manual" />
        <div v-if="!manual">
          <input
            class="input-file"
            type="file"
            @change="exportData"
            accept=".xlsx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <button class="btn btn-success my-5" @click="checkFile">
            Add Question From Excel
          </button>
          <ol type="1">
            <li v-for="(question, index) in questions" :key="index">
              <div class="row" justify-content-between>
                <div class="col my-2 question">{{ question.question }}</div>
                <div class="col-1">({{ question.marks }})</div>
              </div>
              <ol v-if="question.propositions" type="A">
                <li
                  v-for="(item, ind) in question.propositions"
                  :key="ind"
                  :style="
                    item['props'] == question.correct_option
                      ? { color: '#24cb24' }
                      : ''
                  "
                  class="options"
                >
                  {{ item["props"] }}
                </li>
              </ol>
              <div class="col-1" v-if="!question.subType">
                <br />
                Answer: {{ question.correct_option }}
              </div>
              <div class="col-1 explaination-head">
                <br />
                Explanation
                <span class="explaination"> ({{ question.explanation }})</span>
              </div>
              <div class="col-1">
                <br />
                Difficulty ({{ question.difficulty }})
              </div>
              <button
                class="btn btn-danger my-3 mx-2"
                @click="deleteQuestion(index)"
              >
                Delete
              </button>
              <br />
              <hr />
              <br />
            </li>
          </ol>
          <button class="btn btn-success my-5" @click="validate">
            Add Questions
          </button>
        </div>
        <div v-else>
          <div justify-content-between v-if="!edit_open">
            <div class="row">
              <div class="col justify-content-end">
                <button @click="edit_open = true" class="btn btn-primary px-5">
                  Add MCQ
                </button>
              </div>
              <div class="col justify-content-start">
                <button
                  @click="
                    edit_open = true;
                    mcq = false;
                  "
                  class="btn btn-primary"
                >
                  Add Theoritical
                </button>
              </div>
            </div>
            <div class="row">
              <ol type="1">
                <li v-for="(question, index) in questions" :key="index">
                  <div class="row" justify-content-between>
                    <div class="col my-2 question">{{ question.question }}</div>
                    <div class="col-1">({{ question.marks }})</div>
                  </div>
                  <ol v-if="question.propositions" type="A">
                    <li
                      v-for="(item, ind) in question.propositions"
                      :key="ind"
                      :style="
                        item['props'] == question.correct_option
                          ? { color: '#24cb24' }
                          : ''
                      "
                      class="options"
                    >
                      {{ item["props"] }}
                    </li>
                  </ol>
                  <div class="col-1" v-if="!question.subType">
                    <br />
                    Answer: {{ question.correct_option }}
                  </div>
                  <div class="col-1 explaination-head">
                    <br />
                    Explanation
                    <span class="explaination"
                      >({{ question.explanation }})</span
                    >
                  </div>
                  <div class="col-1">
                    <br />
                    Difficulty ({{ question.difficulty }})
                  </div>
                  <button
                    class="btn btn-danger my-3 mx-2"
                    @click="deleteQuestion(index)"
                  >
                    Delete
                  </button>
                  <br />
                  <hr />
                  <br />
                </li>
              </ol>
            </div>
            <button class="btn btn-success my-5" @click="validate">
              Add Questions
            </button>
          </div>
          <div class="mx-auto editable-ques my-5 py-3" v-else>
            <div>
              <textarea
                class="form-control mb-3"
                v-model="current_section"
                placeholder="Enter Section Name Here"
                rows="1"
                spellcheck="true"
              ></textarea>
            </div>
            <div>
              <textarea
                class="form-control mb-3"
                v-model="current_question"
                placeholder="Enter Question Here"
                rows="10"
                spellcheck="true"
              ></textarea>
            </div>
            <div v-if="mcq">
              <div
                class="row my-1"
                v-for="(item, index) in current_options"
                :key="index"
              >
                <input
                  class="form-control col-1 align-self-center"
                  type="radio"
                  :value="index"
                  v-model="selected_option"
                />
                <input
                  class="form-control my-1 col"
                  v-model="current_options[index]"
                  :placeholder="'Option ' + (index + 1)"
                />
              </div>
              <div class="btn btn-primary ml-4 my-3" @click="addOption">
                Add Option
              </div>
            </div>
            <div v-else>
              <select
                v-model="subType"
                class="form-control mb-4"
                name="subType"
              >
                <option disabled value="-1">Select Type of Question</option>
                <option value="0">OneWord</option>
                <option value="1">Long/Short Answer</option>
              </select>
            </div>
            <div v-if="subType == 0">
              <input
                class="form-control my-1 col"
                v-model="correct_option"
                maxlength="100"
                :placeholder="'Correct Answer'"
              />
            </div>
            <label>Marks</label>
            <div>
              <input
                class="form-control"
                type="number"
                id="marks"
                v-model="marks"
                min="1"
              />
            </div>
            <small>This question marks</small>
            <br />
            <span>Difficulty: </span>
            <select v-model="selected">
              <option disabled value="">Please select difficulty</option>
              <option>Easy</option>
              <option>Medium</option>
              <option>Hard</option>
            </select>
            <div>
              <textarea
                class="form-control mb-3"
                v-model="explanation"
                placeholder="Enter Explanation Here"
                rows="10"
                spellcheck="true"
              ></textarea>
            </div>
            <div>
              <button class="btn btn-primary" @click="validate1">
                Add Question
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="view questions" id="questions-body" v-else>
        <h1>Question Bank</h1>

        <div class="search-bar">
          <div class="btn-container">
            <button
              class="btn btn-success "
              @click="addorview(1)"
              :style="select == 1 ? { 'background-color': '#ff8484' } : ''"
            >
              Add Question
            </button>
            <button
              class="btn btn-success "
              @click="addorview(0)"
              :style="select == 0 ? { 'background-color': '#ff8484' } : ''"
            >
              View Question
            </button>
          </div>
          <div class="search">
            <label class=" ">Section</label>
            <select
              v-model="current_section_name"
              class="placeholder section"
              name="currentSectionName"
              @change="changeQB"
            >
              <option value="">All</option>
              <option v-for="(section, index) in section" :key="index">
                {{ section.section }}
              </option>
            </select>
            <br />
            <div style="display: flex">
              <label class=" ">Difficulty</label>
              <select
                class="placeholder"
                v-model="difficulty"
                name="difficulty"
              >
                <option value="">Select Difficulty</option>
                <option>Easy</option>
                <option>Medium</option>
                <option>Hard</option>
              </select>
            </div>
          </div>
        </div>
        <br />

        <ol type="1">
          <li
            v-for="(question, index) in filters || filteredListFun"
            :key="index"
          >
            <div class="row" justify-content-between>
              <div class="col-1 question-section">{{ question.section }}</div>
              <div class="col-1 left question-marks">
                ({{ question.marks }})
              </div>
              <div class="col-1 left question-difficulty">
                ({{ question.difficulty }})
              </div>
            </div>
            <div class="row" justify-content-between>
              <div class="col my-2 question">{{ question.question }}</div>
            </div>
            <ol v-if="question.propositions" type="A">
              <li
                v-for="(item, ind) in question.propositions"
                :key="ind"
                :style="
                  item['props'] == question.correct_option
                    ? { color: '#24cb24' }
                    : ''
                "
                class="options"
              >
                {{ item["props"] }}
              </li>
            </ol>
            <div class="col-1" v-if="!question.subType">
              Answer: {{ question.correct_option }}
            </div>
            <div class="col-1 explaination-head">
              Explanation
              <span class="explaination"> ({{ question.explanation }})</span>
            </div>
            <button
              class="btn btn-danger "
              @click="deletequestion(index, question.question_id)"
            >
              Delete
            </button>

            <hr />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
<script>
import db from "../../firebase.js";
import Header from "@/components/Header";
import store from "@/store";
import XLSX from "xlsx";
import SearchBar from "@/components/SearchBar";

export default {
  components: { Header, SearchBar },
  async beforeMount() {
    const docs = await db.collection("section").get();
    this.section = docs.docs.map(doc => doc.data());
    this.questionBank = [];
    var sect = db
      .collection("questionBank")
      .orderBy("id", "desc")
      .limit(5);
    sect = await sect.get();
    sect = sect.docs.map(doc => doc.data());
    // sect = sect.reverse();
    this.questionBank = sect;
    store.state.loading = false;
  },
  mounted() {
    if (window.addEventListener) {
      window.addEventListener("scroll", this.scroll);
    } else if (window.attachEvent) {
      window.attachEvent("onscroll", this.scroll);
    }
  },

  data() {
    return {
      manual: 0,
      select: 0,
      edit_open: false,
      mcq: true,
      selected: "",
      selected_option: -1,
      current_question: "",
      current_options: ["", ""],
      correct_option: "",
      marks: -1,
      current_section: "",
      questions: [],
      questionBank: [],
      section: [],
      sectionName: "",
      difficulty: "",
      explanation: "",
      questionName: "",
      docx: "",
      subType: 1,
      current_section_name: "",
      searchQuery: "",
      oldQuery: ""
    };
  },
  methods: {
    highlight(text) {
      var instance = new Mark(document.querySelector(".question"));
      instance.mark(text);
    },
    unMark(text) {
      var instance = new Mark(document.querySelector(".question"));
      instance.unmark(text);
    },
    searched(data) {
      this.unMark(this.oldQuery);
      this.searchQuery = data;
      this.highlight(data);
      this.oldQuery = data;
    },
    checkFile() {
      document.querySelector(".input-file").click();
    },
    scroll(ev) {
      var st = Math.max(
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      st = st + document.documentElement.clientHeight;
      var x = Math.abs(st - document.documentElement.scrollHeight);
      console.log(x);
      if (x <= 2) {
        this.getNextPage(this.current_section_name);
      }
    },
    async getNextPage(sectionName) {
      if (this.select == 0) {
        var q = this.questionBank.length;
        if (sectionName == "") var x = db.collection("questionBank");
        else
          var x = db
            .collection("questionBank")
            .where("section", "==", sectionName);
        if (this.difficulty != "")
          var sect = x
            .where("difficulty", "==", this.difficulty)
            .where("id", "<", this.questionBank[q - 1].id)
            .orderBy("id", "desc")
            .limit(5);
        else
          var sect = x
            .where("id", "<", this.questionBank[q - 1].id)
            .orderBy("id", "desc")
            .limit(5);
        sect = await sect.get();
        sect = sect.docs.map(doc => doc.data());
        this.questionBank.push(...sect);
      }
    },
    async addorview(i) {
      this.select = i;
      if (i == 0) {
        const docs = await db.collection("section").get();
        this.section = docs.docs.map(doc => doc.data());
        this.questionBank = [];
        if (this.difficulty != "")
          var sect = db
            .collection("questionBank")
            .where("difficulty", "==", this.difficulty)
            .orderBy("id", "desc")
            .limit(5);
        else
          var sect = db
            .collection("questionBank")
            .orderBy("id", "desc")
            .limit(5);
        sect = await sect.get();
        sect = sect.docs.map(doc => doc.data());
        // sect = sect.reverse();
        this.questionBank = sect;
      }
    },
    deletequestion(i, index) {
      var x = this.questionBank.slice(0, i);
      for (var j = 0; j < i; j++) {
        x[j].id--;
        db.collection("questionBank")
          .doc(x[j].question_id)
          .update({
            id: x[j].id
          });
      }
      db.collection("questionBank")
        .doc(index)
        .delete();
      this.addorview(0);
    },
    exportData(event) {
      if (!event.currentTarget.files.length) {
        return;
      }
      var questions = this.questions;
      const that = this;
      // Get the file object
      var f = event.currentTarget.files[0];
      // Use FileReader to read
      var reader = new FileReader();
      // Rewrite the readAsBinaryString method on FileReader
      FileReader.prototype.readAsBinaryString = function(f) {
        var binary = "";
        var wb; // Read the completed data
        var outdata; // the data you need
        var reader = new FileReader();
        reader.onload = function(e) {
          // Read into Uint8Array and convert to Unicode encoding (Unicode takes two bytes)
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // Next is xlsx, see the plugin api for details
          wb = XLSX.read(binary, {
            type: "binary"
          });
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // Custom method to pass data to parent component
          var question = "";
          var marks = 0;
          var explanation = "";
          var correct = "";
          var section = "";
          var difficulty = "";
          for (var q in outdata) {
            question = outdata[q].question;
            difficulty = outdata[q].difficulty;
            marks = outdata[q].marks;
            explanation = outdata[q].explanation;
            section = outdata[q].section.toString();
            var di = {};
            di["question"] = question;
            di["marks"] = marks;
            di["section"] = section;
            di["explanation"] = explanation;
            di["difficulty"] = difficulty;
            di["subType"] = 1;
            if (outdata[q].correct != null) {
              correct = outdata[q].correct.toString();
              if (outdata[q].options != null) {
                var temp_options = outdata[q].options.split(";");
                var options = [];
                for (var x in temp_options) {
                  options.push({
                    props: temp_options[x].toString()
                  });
                }
                di["propositions"] = options;
              } else {
                di["subType"] = 0;
              }
              di["correct_option"] = correct;
            }
            questions.push(di);
          }
          that.$emit("getResult", outdata);
        };
        reader.readAsArrayBuffer(f);
      };
      this.questions = questions;
      reader.readAsBinaryString(f);
    },
    validate1() {
      if (this.current_question.length == 0) {
        swal("Question Length should be greater than zero");
        return;
      }
      if (this.mcq) {
        if (this.selected_option == -1) {
          swal("Select a valid option");
          return;
        }
        this.correct_option = this.current_options[this.selected_option];
        for (var i = 0; i < this.current_options.length; i++) {
          if (this.current_options[i] == "") {
            swal("Fill up all options");
            return;
          }
        }
      }
      if (this.marks <= 0) {
        swal("Set marks >0");
        return;
      }
      var di = {};
      di["question"] = this.current_question;
      di["propositions"] = [];
      if (this.mcq) {
        for (var i = 0; i < this.current_options.length; i++) {
          di["propositions"].push({
            props: this.current_options[i]
          });
        }
      }
      di["correct_option"] = this.correct_option;
      di["marks"] = +this.marks;
      di["section"] = this.current_section.toString();
      di["explanation"] = this.explanation;
      di["difficulty"] = this.selected;
      di["subType"] = +this.subType;
      this.questions.push(di);
      this.init();
    },
    init() {
      (this.edit_open = false),
        (this.mcq = true),
        (this.current_question = ""),
        (this.current_options = ["", ""]),
        (this.correct_option = ""),
        (this.explanation = ""),
        (this.marks = -1),
        (this.subType = 1);
    },
    addOption() {
      this.current_options.push("");
    },
    deleteQuestion(index) {
      if (index == 0) this.questions.shift();
      else {
        console.log(index);
        console.log(this.questions.splice(index, 1));
      }
    },
    filter(array) {
      if (this.difficulty != "") {
        let arr = array.filter(question => {
          return (
            !this.difficulty ||
            question.difficulty
              .toLowerCase()
              .indexOf(this.difficulty.toLowerCase()) > -1
          );
        });
        return arr.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.question.toLowerCase().includes(v));
        });
      } else return null;
    },
    async changeQB() {
      if (this.current_section_name != "") {
        var sect = db
          .collection("questionBank")
          .where("section", "==", this.current_section_name)
          .orderBy("id", "desc")
          .limit(5);
        sect = await sect.get();
        sect = sect.docs.map(doc => doc.data());
        console.log(sect);
        this.questionBank = sect;
      } else {
        var sect = db
          .collection("questionBank")
          .orderBy("id", "desc")
          .limit(5);
        sect = await sect.get();
        sect = sect.docs.map(doc => doc.data());
        console.log(sect);
        this.questionBank = sect;
      }
    },
    async validate() {
      var id = await db
        .collection("questionBank")
        .orderBy("id", "desc")
        .limit(1)
        .get();
      id = id.docs.map(doc => doc.data());
      console.log(id);
      if (id.length == 1) id = id[0].id;
      else id = 0;
      console.log(id);
      for (var q in this.questions) {
        id++;
        var di = {};
        di["question"] = this.questions[q].question;
        di["marks"] = +this.questions[q].marks;
        di["section"] = this.questions[q].section;
        di["explanation"] = this.questions[q].explanation;
        di["difficulty"] = this.questions[q].difficulty;
        di["subType"] = this.questions[q].subType;
        if (this.questions[q].subType == 0) {
          console.log("OneWord");
          di["correct_option"] = this.questions[q].correct_option;
          await db
            .collection("questionBank")
            .add({
              question: di.question,
              marks: di.marks,
              correct_option: di.correct_option,
              section: di.section,
              explanation: di.explanation,
              difficulty: di.difficulty,
              subType: di.subType,
              id: id
            })
            .then(doc => {
              this.docx = doc.id;
            });
          await db
            .collection("questionBank")
            .doc(this.docx)
            .update({
              question_id: this.docx
            });
        } else if (this.questions[q].correct_option) {
          console.log("MCQ");
          di["propositions"] = this.questions[q].propositions;
          di["correct_option"] = this.questions[q].correct_option;
          await db
            .collection("questionBank")
            .add({
              question: di.question,
              marks: di.marks,
              propositions: di.propositions,
              correct_option: di.correct_option,
              section: di.section,
              explanation: di.explanation,
              difficulty: di.difficulty,
              subType: di.subType,
              id: id
            })
            .then(doc => {
              this.docx = doc.id;
            });

          await db
            .collection("questionBank")
            .doc(this.docx)
            .update({
              question_id: this.docx
            });
        } else {
          console.log("long");
          db.collection("questionBank")
            .add({
              question: di.question,
              marks: di.marks,
              section: di.section,
              explanation: di.explanation,
              difficulty: di.difficulty,
              id: id,
              subType: di.subType
            })
            .then(doc => {
              this.docx = doc.id;
            });

          db.collection("questionBank")
            .doc(this.docx)
            .update({
              question_id: this.docx
            });
        }
        console.log(id);
        db.collection("section")
          .doc(di.section)
          .set({
            section: di.section
          });
      }
      this.questions = [];
      this.addorview(0);
    }
  },
  computed: {
    filter2() {
      if (this.current_section_name != "")
        return this.questionBank.filter(question => {
          return (
            !this.current_section_name ||
            question.section
              .toLowerCase()
              .indexOf(this.current_section_name.toLowerCase()) > -1
          );
        });
      else return null;
    },
    filters() {
      if (this.filter2 == null) {
        return this.filter(this.questionBank) || this.filter2;
      } else {
        return this.filter(this.filter2) || this.filter2;
      }
    },
    filteredListFun() {
      if (this.searchQuery) {
        return this.questionBank.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.question.toLowerCase().includes(v));
        });
      } else {
        return this.questionBank;
      }
    }
    // filterCheck(){
    //     if(this.filters.length<=3){
    //         this.getNextPage();
    //     }
    // }
  }
};
</script>
<style scoped>
.full {
  background-color: var(--light-gray);
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: center;
  align-items: center;
  background-color: var(--light-gray);
  border-radius: 100px;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  margin: auto;
}

.questions {
  font-size: 20;
  font-weight: 600;
  width: 90%;

  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
  box-shadow: 0 4px 10px hsla(0, 0%, 0%, 10%);
  box-sizing: border-box;
  padding: 20px;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0rem rgb(40 167 69 / 50%);
}

.button {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 150px;
  background-color: #d42525;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  margin: auto;
}

.full {
  font-family: Georgia, serif;
  margin: 0;
  letter-spacing: 2px;
}

.btn-primary {
  background-color: #ff7a7a;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}

.btn-success {
  background-color: #ff3c3c;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}
.section {
  margin-left: 21px;
}
.search-bar {
  position: fixed;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: white;

  box-sizing: border-box;

  margin: 10px auto auto;
  box-shadow: 0 4px 10px hsla(0, 0%, 0%, 10%);
  border-radius: 20px;
  z-index: 2222;
  opacity: 1;
  padding: 20px;
}

.search {
  font-size: 20;
  font-weight: 600;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
}

.placeholder {
  padding: 5px;
  border-radius: 3px;
}

.input-file {
  display: none;
}

#preloader {
  display: flex;
  justify-content: center;
  color: red;
  align-items: center;
  height: 100vh;
  --sk-size: 60px;
  --sk-color: red;
}

.left {
  transform: translate(35vw);
}
.btn-success {
  margin: 10px !important;
}

@media only screen and (max-width: 1024px) {
  .questions {
    width: 100vmin;
    padding: 10vmin;
  }
}
@media only screen and (max-width: 1400px) {
  .search-bar {
    position: relative;
    right: 0;
  }
  .search {
    display: flex;
    padding-inline: 10px;
  }
  select {
    margin-right: 10px;
  }
  .btn-container {
    margin: 0 auto;
    width: min-content;
    display: flex;
    margin-bottom: 10px;
  }
}

.question-section {
  color: rgb(126, 131, 150);
  font-size: 15px;
  font-family: monospace;
  text-transform: uppercase;
}

.question-marks {
  font-weight: 500;
  color: #757575;
}

.question-difficulty {
  font-weight: 500;
  color: #757575;
}

.explaination-head {
  font-weight: 700;
  color: #757575;
  text-transform: uppercase;
  font-family: monospace;
}

.explaination {
  font-weight: 500;
  color: #757575;
}

.options {
  font-weight: 500;
}
</style>
